import React from 'react';
import logo from './luna.png';
import footerlogo from './footerlogo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          I am the best doggo!
        </p>
        <a
          className="App-link"
          href="https://itsprobablyababy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Play my game!
        </a>
      </header>

      <footer className="App-footer">
        <img
          src={footerlogo}
          className="App-logo-footer"
          alt="SLD-logo"
          style={{ marginRight: '5px' }}
        />
        <a href="http://sleepylunadesigns.com" className="App-link" style={{ color: 'black' }}>
          Sleepy Luna Designs | &copy; 2018 - present
        </a>
      </footer>
    </div>
  );
}

export default App;
